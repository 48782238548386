export const DATE_FORMAT = 'MMM DD, YYYY';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY h:mm A z';
export const DATE_TIME_FORMAT_WITH_SECONDS = 'MM/DD/YYYY h:mm:ss A';

export const LAC_EVENT_PER_MIN_RATE = 0.6;
export const HAL_EVENT_PER_MIN_RATE = 2.5;

export const MAXIMUM_EMAIL_REMINDER_TIME = 120;

export const MINIMUM_STREAM_WAIT_TIME = 10;
export const MAXIMUM_STREAM_WAIT_TIME = 120;

export const MAXIMUM_CAPTIONING_DELAY = 15000;
export const MINIMUM_CAPTIONING_DELAY = 2000;

export const PROFANITY_FILTER_LEVELS = {
  NONE: 0,
  NORMAL: 1,
  HIGH: 2,
  STRICT: 3,
};

export const PROFANITY_FILTER_OPTIONS = [
  {
    name: 'None',
    level: PROFANITY_FILTER_LEVELS.NONE,
    description: 'No offensive or explicit words will be filtered.',
  },
  {
    name: 'Normal',
    level: PROFANITY_FILTER_LEVELS.NORMAL,
    description:
      'Most explicit and offensive words will be filtered out. Recommended as the default setting.',
  },
  {
    name: 'Strict',
    level: PROFANITY_FILTER_LEVELS.STRICT,
    description: 'All explicit and offensive words and phrases will be filtered out and replaced.',
  },
];

export const YOUTUBE_VIDEO_UNAVAILABLE_MESSAGE =
  'This video is unavailable because it is private or deleted';
